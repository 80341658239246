import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringOrNumber } from '@chakra-ui/utils';
// import Question from 'core/model/question';
import Options from 'components/Blocks/Options/Options';
import OptionsWrapper from 'components/Blocks/OptionsWrapper/OptionsWrapper';
import Button from 'components/Button';
import RatioSection from 'components/Blocks/RatioCardWrapper';
// import MultipleChoice from './MultipleChoice';

interface QuestionProps {
    question: { [x: string]: any };
    handleNextClick: (step: number, score: number | undefined) => void;
    handlePrevClick: () => void;
    answers: { step: number; score: number }[];
}

const QuestionTile: FC<QuestionProps> = ({ question, answers, handleNextClick, handlePrevClick }: QuestionProps) => {
    const { t } = useTranslation();

    const defaultValue = answers?.find((item) => item.step === question?.step)?.score || 0;

    const [value, setValue] = useState<number>(defaultValue);
    const [multipleValues, setMultipleValues] = useState<string[]>([]);
    const [disableCheckbox, setDisableCheckbox] = useState<boolean>(false);

    useEffect(() => {
        if (multipleValues.length > 0 && multipleValues.includes('0')) {
            setDisableCheckbox(true);
            setValue(0);
        } else {
            setDisableCheckbox(false);
            setValue(multipleValues.length);
        }
    }, [multipleValues]);

    const [answersByText, setAnswersByText] = useState<{ text: string; title?: string; scores: number[] }[]>([]);

    useEffect(() => {
        const data: { text: string; title?: string; scores: number[] }[] = [];

        question.responses.reduce((prev, curr) => {
            const existing: { text: string; title?: string; scores: number[] } | undefined = prev.find(
                (r: { text: string; title?: string; scores: number[] }) => {
                    return r.text === curr.text;
                },
            );
            if (existing) {
                existing.scores.push(curr.score as number);
            } else {
                prev.push({ text: curr.text, title: curr.title, scores: [curr.score as number] });
            }

            return prev;
        }, data);

        setAnswersByText(data);
    }, [question]);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    const handleMultipleChoice = (values: StringOrNumber[]) => {
        if (values?.includes('answers.0')) {
            setMultipleValues(['0']);
        } else {
            setMultipleValues(values as string[]);
        }
    };

    const handleClick = () => {
        handleNextClick(question.step, value);
        setValue(0);
    };
    const handleChange = (val: number) => {
        setValue(val);
    };

    const getScore = (score: number | number[]) => {
        if (typeof score === 'number') {
            return score;
        }

        return score.reduce((acc, _item) => acc + _item, 0);
    };
    // pushing i don't know onject at last ndex
    const answersArranged = answersByText.filter((item) => {
        return getScore(item.scores) > 0;
    });

    const answerWithZeroScore = answersByText.find((item) => {
        return getScore(item.scores) === 0;
    });

    answersArranged.push(answerWithZeroScore);

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <>
            {/* <Heading p={2} size="md">
                {t(question.text)}
            </Heading> */}
            {question.multipleChoice ? (
                <OptionsWrapper>
                    {question.responses.map((r) => {
                        return r.score === 0 ? (
                            <div className="full-width-item">
                                <Options
                                    onClick={() => {
                                        if (multipleValues.includes(r.score.toString())) {
                                            handleMultipleChoice([]);
                                        } else {
                                            handleMultipleChoice([r.score.toString()]);
                                        }
                                    }}
                                    selected={multipleValues.includes(r.score.toString())}
                                    title={t(r.text)}
                                />
                            </div>
                        ) : (
                            <div className={question.listView ? 'full-width-item' : ''}>
                                <Options
                                    description={r.title ? t(r.text) : ''}
                                    disabled={disableCheckbox}
                                    onClick={() => {
                                        if (!multipleValues.includes('0')) {
                                            if (multipleValues.includes(r.text)) {
                                                const filtered = multipleValues.filter((item) => item !== r.text);
                                                handleMultipleChoice(filtered);
                                            } else {
                                                handleMultipleChoice([...multipleValues, r.text]);
                                            }
                                        }
                                    }}
                                    selected={multipleValues.includes(r.text)}
                                    title={r.title ? t(r.title) : t(r.text)}
                                />
                            </div>
                        );
                        /* <VStack alignItems="start" minWidth="50px" paddingX="3px">
                                            {r.scores.map((s) => (
                                                <Radio key={s} value={s}>
                                                    {`${s}`}sssm
                                                </Radio>
                                            ))}
                                        </VStack>
                                        <Container alignSelf="center">{t(r.text)}sssd</Container> */
                    })}
                </OptionsWrapper>
            ) : (
                !question.percentageView && (
                    // <OptionsWrapper>
                    //     {question.responses.map((r) => {
                    //         return t(r.text).toLowerCase() === "i don't know" ||
                    //             t(r.text).toLowerCase() === 'je ne sais pas' ? (
                    //             // eslint-disable-next-line react/jsx-indent
                    //             <div style={{ gridColumn: 'span 2 / span 2' }}>
                    //                 <Options
                    //                     onClick={() => {
                    //                         handleMultipleChoice([...multipleValues, r.text]);
                    //                     }}
                    //                     selected={multipleValues.includes(r.text)}
                    //                     title={t(r.text)}
                    //                 />
                    //             </div>
                    //         ) : (
                    //             <div style={{ gridColumn: question.listView ? 'span 2 / span 2' : 'span 1 / span 2' }}>
                    //                 <Options
                    //                     description={r.title ? t(r.text) : ''}
                    //                     onClick={() => {
                    //                         handleMultipleChoice([...multipleValues, r.text]);
                    //                     }}
                    //                     selected={multipleValues.includes(r.text)}
                    //                     title={r.title ? t(r.title) : t(r.text)}
                    //                 />
                    //             </div>
                    //         );
                    //         /* <VStack alignItems="start" minWidth="50px" paddingX="3px">
                    //                             {r.scores.map((s) => (
                    //                                 <Radio key={s} value={s}>
                    //                                     {`${s}`}sssm
                    //                                 </Radio>
                    //                             ))}
                    //                         </VStack>
                    //                         <Container alignSelf="center">{t(r.text)}sssd</Container> */
                    //     })}
                    // </OptionsWrapper>
                    // <OptionsWrapper>
                    //     {answersByText.map((r) => {
                    //         const currentStep = getScore(r?.scores || 0);

                    //         return t(r.text).toLowerCase() === "i don't know" ||
                    //             t(r.text).toLowerCase() === 'je ne sais pas' ? (
                    //             // eslint-disable-next-line react/jsx-indent
                    //             <div style={{ gridColumn: 'span 2 / span 2' }}>
                    //                 <Options
                    //                     onClick={() => {
                    //                         handleMultipleChoice(currentStep);
                    //                     }}
                    //                     selected={currentStep === value}
                    //                     title={t(r.text)}
                    //                 />
                    //             </div>
                    //         ) : (
                    //             <div style={{ gridColumn: question.listView ? 'span 2 / span 2' : 'span 1 / span 2' }}>
                    //                 <Options
                    //                     description={r.title ? t(r.text) : ''}
                    //                     onClick={() => {
                    //                         handleChange(currentStep);
                    //                     }}
                    //                     selected={currentStep === value}
                    //                     title={r.title ? t(r.title) : t(r.text)}
                    //                 />
                    //             </div>
                    //         );
                    //         /* <VStack alignItems="start" minWidth="50px" paddingX="3px">
                    //                                 {r.scores.map((s) => (
                    //                                     <Radio key={s} value={s}>
                    //                                         {`${s}`}sssm
                    //                                     </Radio>
                    //                                 ))}
                    //                             </VStack>
                    //                             <Container alignSelf="center">{t(r.text)}sssd</Container> */
                    //     })}
                    // </OptionsWrapper>
                    // <SliderChoice handleChange={handleChange} question={question} value={value} />
                    <OptionsWrapper>
                        {answersByText.map((r) => {
                            const currentStep = getScore(r?.scores || 0);

                            return (
                                <div className={question.listView || currentStep === 0 ? 'full-width-item' : ''}>
                                    <Options
                                        description={r.title ? t(r.text) : ''}
                                        onClick={() => {
                                            handleChange(currentStep);
                                        }}
                                        selected={currentStep === value}
                                        title={r.title ? t(r.title) : t(r.text)}
                                    />
                                </div>
                            );
                        })}
                    </OptionsWrapper>
                )
            )}
            {question.percentageView && (
                <RatioSection
                    primary={{
                        text: t(question.percentagePrimaryText.text),
                        color: t(question.percentagePrimaryText.color),
                    }}
                    ratioCards={question.responses.map((response) =>
                        Object.assign(t(response.text, { returnObjects: true }), {
                            onClick: () => {
                                handleChange(response.score);
                            },
                            selected: response.score === value,
                            score: response.score,
                        }),
                    )}
                    secondary={{
                        text: t(question.percentageSecondaryText.text),
                        color: t(question.percentageSecondaryText.color),
                    }}
                />
            )}
            <div className="buttons-container">
                <div className="secondary-btn-container">
                    <Button onClick={handlePrevClick} type="secondary">
                        BACK
                    </Button>
                </div>

                <div className="primary-btn-container">
                    <Button
                        disabled={!!(question.multipleChoice && multipleValues?.length === 0)}
                        onClick={handleClick}
                    >
                        NEXT
                    </Button>
                </div>
            </div>
        </>
    );
};

export default QuestionTile;
