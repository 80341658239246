import React, { FC, useState } from 'react';
import { Box } from '@chakra-ui/react';

//
import FormComponent from 'components/Form/Form';
import Seo from 'components/Seo/Seo';
// import Options from 'components/Blocks/Options/Option';
import Layout from 'components/Layout';

const Home: FC = () => {
    const [currentQuesNum, setCurrentQuesNum] = useState(0);
    const handleQuesNum = (data: number) => setCurrentQuesNum(data);
    const images = ['/screen.jpg', '/desk.jpg'];

    return (
        <Box>
            <Seo />
            <Layout image={{ src: images[currentQuesNum], alt: 'Layout 1' }}>
                {/* <Options /> */}
                <FormComponent onQuesChange={handleQuesNum} />
            </Layout>
        </Box>
    );
};

export default Home;
