/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import syncSession from 'core/useCases/session/syncSessionAction';
import User from 'core/model/user';
import { BACKEND_URL } from 'utils/appUrl';
import { POST } from 'utils/http';

interface ResponseData {
    user: User;
}

const sessionFetcher = async () => {
    const { user } = (await POST(`${BACKEND_URL}/users/me`, {
        userId: sessionStorage.getItem('userId'),
    })) as ResponseData;

    return user;
};

const AuthInitializer: FC = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const userId = sessionStorage.getItem('userId');
        if (userId) {
            dispatch(syncSession(sessionFetcher)());
        }
    }, []);

    return <>{children}</>;
};

export default AuthInitializer;
