import { ThunkAction } from 'core/store';
import LoadingState from '../types';
import SessionActionTypes from './types';

export interface SetSessionLoadingAction {
    type: SessionActionTypes.SET_SESSION_LOADING;
    payload: LoadingState;
}

export const setSessionLoading = (loading: LoadingState): SetSessionLoadingAction => ({
    type: SessionActionTypes.SET_SESSION_LOADING,
    payload: loading,
});

const setSessionLoadingDebounce = () => (loading: LoadingState): ThunkAction => (dispatch) => {
    if ([LoadingState.NOT_LOADED, LoadingState.LOADED].includes(loading)) {
        dispatch(setSessionLoading(loading));
    } else {
        dispatch(setSessionLoading(loading));
    }
};

export default setSessionLoadingDebounce;
