import React, { FC } from 'react';
import Graph from 'components/Graph';

const Component2: FC = () => {
    return (
        <div>
            <Graph color="#A8230D" data={[[29.9, 29.9, 18.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 148.5]]} />
            <Graph color="#0153F1" data={[[29.9, 29.9, 18.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 148.5]]} />
        </div>
    );
};

export default Component2;
