import React, { FC, Ref, useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
// import highchartsGantt from 'highcharts/modules/gantt';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import hcMore from 'highcharts/highcharts-more';

import { useDispatch } from 'react-redux';
// import { Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';
import { RadarGraphDataType } from 'core/model/question';
import i18n from 'i18n';
import { loadRadarAction } from 'core/useCases/graphs/graphActions';

hcMore(Highcharts);
type RadarGraphProps = {
    complexityRadarGraphData: RadarGraphDataType;
    maturityRadarGraphData: RadarGraphDataType;
    t: TFunction<'translation'>;
    radarRef: Ref<HTMLDivElement>;
};
const RadarGraph: FC<RadarGraphProps> = ({ complexityRadarGraphData, maturityRadarGraphData, t, radarRef }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, setData] = useState<{ subject: string; complexity: number; maturity: number }[]>();

    useEffect(() => {
        if (complexityRadarGraphData) {
            const userData = Object.keys(complexityRadarGraphData).map((k) => {
                return {
                    subject: t(`graphs.radar.${k}`),
                    complexity: complexityRadarGraphData[k as keyof RadarGraphDataType],
                    maturity: maturityRadarGraphData[k as keyof RadarGraphDataType] * 100,
                };
            });
            setData(userData);
        }
    }, [complexityRadarGraphData, i18n.language]);

    const dispatch = useDispatch();
    useEffect(() => {
        const svg = document.getElementsByTagName('svg');

        if (svg.length > 0) {
            dispatch(loadRadarAction(true));
        }
    }, [document]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const customTick = ({ payload, x, y, stroke, radius }: any) => {
    //     return (
    //         <g className="recharts-layer recharts-polar-angle-axis-tick">
    //             <text
    //                 className="recharts-text recharts-polar-angle-axis-tick-value"
    //                 radius={radius}
    //                 stroke={stroke}
    //                 textAnchor="middle" // {textAnchor}
    //                 x={x}
    //                 y={y}
    //             >
    //                 {payload.index === 4 ? (
    //                     <>
    //                         <tspan dy="0em" x={x}>
    //                             {payload.value.substr(0, payload.value.indexOf(' '))}
    //                         </tspan>
    //                         <tspan dy="1em" x={x}>
    //                             {payload.value.substr(payload.value.indexOf(' ') + 1)}
    //                         </tspan>
    //                     </>
    //                 ) : (
    //                     <tspan dy="0em" x={x}>
    //                         {payload.value}
    //                     </tspan>
    //                 )}
    //             </text>
    //         </g>
    //     );
    // };
    // const chartOptions = {
    //     chart: {
    //         polar: true,
    //     },
    //     legend: {
    //         align: 'right',
    //         verticalAlign: 'top',
    //         y: 100,
    //         layout: 'vertical',
    //     },

    //     title: {
    //         text: 'Highcharts Polar Chart',
    //     },

    //     subtitle: {
    //         text: 'Also known as Radar Chart',
    //     },

    //     pane: {
    //         startAngle: 0,
    //         endAngle: 360,
    //     },

    //     xAxis: {
    //         categories: ['Human resources', 'Data', 'Tools', 'Organization', 'Monitoring'],
    //         tickmarkPlacement: 'on',
    //         lineWidth: 0,
    //     },

    //     yAxis: {
    //         gridLineInterpolation: 'circle',
    //         lineWidth: 0,
    //         min: 0,
    //     },

    //     plotOptions: {
    //         series: {
    //             pointStart: 0,
    //             pointInterval: 45,
    //         },
    //         column: {
    //             pointPadding: 0,
    //             groupPadding: 0,
    //         },
    //     },

    //     series: [
    //         // {
    //         //     type: 'column',
    //         //     name: 'Column',
    //         //     data: [8, 7, 6, 5, 4, 3, 2, 1],
    //         //     pointPlacement: 'between',
    //         // },
    //         {
    //             type: 'line',
    //             name: 'Line',
    //             data: [1, 2, 3, 4, 5, 6, 7, 8],
    //         },
    //         {
    //             type: 'line',
    //             name: 'Line',
    //             data: [12, 11, 0, 15, 25, 36, 17, 28],
    //         },
    //         // {
    //         //     type: 'area',
    //         //     name: 'Area',
    //         //     data: [1, 8, 2, 7, 3, 6, 4, 5],
    //         // },
    //     ],
    // };
    const chartOptions = {
        chart: {
            polar: true,
            type: 'line',
        },
        xAxis: {
            categories: ['Human Resources', 'Data', 'Tools', 'Organization', 'Monitoring'],
        },
        title: {
            text: null,
        },
        yAxis: {
            min: 0,
            max: 100,
            tickInterval: 20,
            labels: {
                format: '{value}%',
            },
        },
        plotOptions: {
            series: {
                pointStart: 0,
                pointInterval: 1,
            },
        },
        credits: {
            enabled: false, // Set enabled to false to hide credits
        },
        series: [
            {
                name: 'Pricing Capacity',
                data:
                    [32, 22, 33, 18, 36] ||
                    (maturityRadarGraphData &&
                        Object?.values(maturityRadarGraphData)?.map((i) => Number(i.toFixed(2)))), // Replace with your data values
                pointPlacement: 'on',
                marker: false,
                color: '#fd612d',
                lineColor: '#fd612d',
                linecap: 'none', // To make lines curved
            },
            {
                name: 'Pricing Complexity',
                data: (complexityRadarGraphData &&
                    Object?.values(complexityRadarGraphData)?.map((i) => Number(i.toFixed(2)))) || [0, 0, 0, 0, 0], // Replace with your data values
                pointPlacement: 'on',
                marker: false,
                color: '#0153f1',
                lineColor: '#0153f1',
                linecap: 'none', // To make lines curved
            },
        ],
    };

    return (
        <div ref={radarRef} className="radarGraph" id="radarGraph">
            <div className="radar-graph-container">
                <h4 className="heading-4">{t('result.graph2')}</h4>
                <HighchartsReact highcharts={Highcharts} options={chartOptions} />
                {/* <LineGraph color="#0153F1" data={data?.map((item) => item.complexity)} /> */}
            </div>
        </div>
    );
};

export default RadarGraph;
