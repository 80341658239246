import React, { FC, Ref, useEffect } from 'react';
import { CartesianGrid, Dot, Label, Scatter, ScatterChart, XAxis, YAxis } from 'recharts';
import { TFunction } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { loadScatterAction } from 'core/useCases/graphs/graphActions';
import Graph from 'components/Graph';

type ScatterGraphProps = {
    pricingComplexityScore: number;
    pricingMaturityScore: number;
    t: TFunction<'translation'>;
    scatterRef: Ref<HTMLDivElement>;
};

// function ReferenceBands() {
//     return (
//         <g>
//             <path d="M 80 20 V 80 0 L 380 0 V 380 20 z" fill="purple" fillOpacity="20%" />
//             <path d="M 80 340 V 80 20 L 370 20 z" fill="green" fillOpacity="20%" />
//             <path d="M 90 350 L 230 350 L 380 185 V 380 30 z" fill="orange" fillOpacity="20%" />
//             <path d="M 80 350 V 80 340 L 370 20 L 380 20 V 380 30 L 90 350 z" fill="blue" fillOpacity="20%" />
//             <path d="M 230 350 L 380 350 V 380 185 z" fill="red" fillOpacity="20%" />
//         </g>
//     );
// }

const ScatterGraph: FC<ScatterGraphProps> = ({
    pricingComplexityScore,
    pricingMaturityScore,
    t,
    scatterRef,
}: ScatterGraphProps) => {
    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CustomizedShape = (props: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { cx, cy, fill, riderName } = props;

        return (
            <g>
                <Dot cx={cx} cy={cy} fill={fill} r={5} />
                <g transform={`translate(${cx},${cy})`}>
                    <text dy={4} textAnchor="left" x={10} y={0}>
                        {t('you')}
                    </text>
                </g>
            </g>
        );
    };

    useEffect(() => {
        const svg = document.getElementsByTagName('svg');

        if (svg.length > 0) {
            dispatch(loadScatterAction(true));
        }
    }, [document]);

    return (
        <div ref={scatterRef} className="radarGraph" id="scatterGraph">
            <div className="scatter-graph-container">
                <h4 className="heading-4">{t('result.graph1')}</h4>
                <div style={{ display: 'none' }}>
                    <ScatterChart
                        height={475}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                        style={{
                            backgroundImage: `url('${process.env.PUBLIC_URL}/background.jpg')`,
                            backgroundSize: '311px 365px',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '72px 67px',
                        }}
                        width={400}
                    >
                        <CartesianGrid />
                        <XAxis
                            dataKey="x"
                            domain={[0, 100]}
                            label={{ value: t('graphs.scatter.x'), position: 'bottom' }}
                            tickCount={3}
                            type="number"
                            unit="%"
                        />
                        <YAxis
                            dataKey="y"
                            domain={[0, 150]}
                            label={
                                <Label angle={-90} position={{ x: 0, y: 100 }}>
                                    {t('graphs.scatter.y')}
                                </Label>
                            }
                            tickCount={4}
                            type="number"
                            unit="%"
                        />
                        <Scatter
                            data={[{ x: pricingComplexityScore, y: pricingMaturityScore }]}
                            fill="#193fff"
                            shape={<CustomizedShape />}
                        />
                    </ScatterChart>
                </div>
                <Graph color="#A8230D" data={[[pricingComplexityScore, pricingMaturityScore]]} />

                {/* <Center>
                    <HStack mt="5px" spacing={2}>
                        <Tag backgroundColor="purple.100">Leader zone</Tag>
                        <Tag backgroundColor="green.100">Inovation zone</Tag>
                        <Tag backgroundColor="blue.100">Comfort zone</Tag>
                        <Tag backgroundColor="orange.100">Warning zone</Tag>
                        <Tag backgroundColor="red.100">Danger zone</Tag>
                    </HStack>
                </Center> */}
            </div>
        </div>
    );
};

export default ScatterGraph;
