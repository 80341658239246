enum SessionActionTypes {
    SET_SESSION_USER = 'SET_SESSION_USER',
    LOG_IN_ERROR = 'LOG_IN_ERROR',
    CLEAR_SESSION_USER = 'CLEAR_SESSION_USER',
    SIGN_UP_ERROR = 'SIGN_UP_ERROR',
    SET_USER_DATA = 'SET_USER_DATA',
    SET_SESSION_LOADING = 'SET_SESSION_LOADING',
    UPDATE_USER_PASSWORD_ERROR = 'UPDATE_USER_PASSWORD_ERROR',
    SELECT_MENU_ITEM = 'SELECT_MENU_ITEM',
    SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS',
    RESET_SIGNUP_USER_SUCCESS = 'RESET_SIGNUP_USER_SUCCESS',
}

export enum LoginErrorType {
    AUTH_ERROR = 'authError',
    UNKNOWN_ERROR = 'unknownError',
}

export enum SignupErrorType {
    DUPLICATE_ERROR = 'duplicateError',
    UNKNOWN_ERROR = 'unknownError',
}

export enum UpdateUserPasswordErrorType {
    INVALID_PASSWORD = 'invalidPassword',
    TOKEN_ERROR = 'tokenError',
    UNKNOWN_ERROR = 'unknownError',
}

export interface UserData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export default SessionActionTypes;
