import React from 'react';

type OptionsWrapperProps = {
    children: React.ReactNode;
};
const OptionsWrapper: React.FC<OptionsWrapperProps> = ({ children }) => {
    return <div className="options-wrapper">{children}</div>;
};

export default OptionsWrapper;
