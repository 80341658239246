import React from 'react';
import Button from 'components/Button';

type StepProps = {
    showProgessBar?: boolean;
    mainTitle?: string;
    mainDescription?: string;
    title?: string;
    description?: string;
    children: React.ReactNode;
    primaryBtn?: {
        onClick?: (index: number) => void;
        title: string;
        disabled?: boolean;
    };
    secondaryBtn?: {
        onClick?: () => void;
        title: string;
        disabled?: boolean;
    };
};
const Step: React.FC<StepProps> = ({
    title,
    mainTitle,
    mainDescription,
    description,
    children,
    primaryBtn,
    secondaryBtn,
}) => {
    return (
        <div className="step-container">
            {mainTitle && <h3 className="heading-3 main-title">{mainTitle}</h3>}
            {mainDescription && <p className="text-md main-description">{mainDescription}</p>}
            {title && <h4 className="heading-4 title">{title}</h4>}
            {description && <p className="text-md description">{description}</p>}

            <div className="options-wrapper-container">{children}</div>
            <div className="buttons-container">
                {secondaryBtn && (
                    <div className="secondary-btn-container">
                        <Button disabled={secondaryBtn.disabled} onClick={secondaryBtn?.onClick} type="secondary">
                            {secondaryBtn.title}
                        </Button>
                    </div>
                )}
                {primaryBtn && (
                    <div className="primary-btn-container">
                        <Button disabled={primaryBtn.disabled} onClick={() => primaryBtn?.onClick(2)}>
                            {primaryBtn.title}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Step;
