import React from 'react';
import Header from 'components/Header/Header';

type LayoutProps = {
    image: {
        alt: string;
        src: string;
    };
    children: React.ReactNode;
};
const Layout: React.FC<LayoutProps> = ({ children, image }) => {
    return (
        <div className="layout-container">
            <div className="left-container">
                <div className="logo-bg-container">
                    <img alt="logo 2" src={`${process.env.PUBLIC_URL}/pricemoov-logo-2.svg`} />
                </div>
                <div className="content-container">
                    <div className="header">
                        <Header />
                    </div>
                    <div className="body">{children}</div>
                    <div className="footer">
                        <span className="text-md">
                            <a href="/">© 2022 Pricemoov. All rights reserved.</a>
                        </span>
                        <span className="text-md">
                            {' '}
                            <a href="/">Privacy Policy</a>
                        </span>
                        <span className="text-md">
                            {' '}
                            <a href="/">Terms & Conditions</a>
                        </span>
                    </div>
                </div>
            </div>
            <div className="right-container">
                <img alt="background" src={image.src} />
            </div>
        </div>
    );
};

export default Layout;
