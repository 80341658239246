import React, { FC } from 'react';
import { Router as BaseRouter, Switch, Route, Redirect } from 'react-router';
import history from 'utils/history';
import Component from './Component';
import Component2 from './Component2';
import HomePage from './Home/Home';
import Results from './Results/Results';

const Router: FC = () => {
    return (
        <BaseRouter history={history}>
            <Switch>
                <Route path="/" exact>
                    <HomePage />
                </Route>
                <Route path="/component" exact>
                    <Component />
                </Route>
                <Route path="/component2" exact>
                    <Component2 />
                </Route>
                <Route path="/results/:email" exact>
                    <Results />
                </Route>
                <Route path="*" exact>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </BaseRouter>
    );
};

export default Router;
