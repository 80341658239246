import User, { EmailData, UserData } from 'core/model/user';
import { ThunkAction } from 'core/store';
import UsersActionTypes from './types';

export interface AddUserAction {
    type: UsersActionTypes.ADD_USER;
    payload: User;
}

export const addUserAction = (user: User): AddUserAction => ({
    type: UsersActionTypes.ADD_USER,
    payload: user,
});

export type AddUserFetcher = (Args: UserData) => Promise<User>;

// eslint-disable-next-line consistent-return
export const addUser = (fetcher: AddUserFetcher) => (userData: UserData): ThunkAction => async (dispatch) => {
    try {
        const user = await fetcher(userData);
        sessionStorage.setItem('userId', user.id);

        dispatch(addUserAction(user));

        return user;
    } catch (error) {
        console.error(error);
    }
};

export interface GetUserAction {
    type: UsersActionTypes.GET_USER;
    payload: User;
}

export const getUserAction = (user: User): GetUserAction => ({
    type: UsersActionTypes.GET_USER,
    payload: user,
});

export type GetUserFetcher = (Args: EmailData) => Promise<User>;

export const getUser = (fetcher: GetUserFetcher) => (userData: EmailData): ThunkAction => async (dispatch) => {
    try {
        const user = await fetcher(userData);
        dispatch(getUserAction(user));
    } catch (error) {
        console.error(error);
    }
};
