enum UsersActionTypes {
    FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
    DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
    RESET_DELETE_USER_SUCCESS = 'RESET_DELETE_USER_SUCCESS',
    SET_USER_ERROR = 'SET_USER_ERROR',
    ADD_USER = 'ADD_USER',
    UPDATE_USER = 'UPDATE_USER',
    EDIT_USER = 'EDIT_USER',
    GET_USER = 'GET_USER',
}

export enum UserErrorType {
    DUPLICATE_ERROR = 'duplicateError',
    UNKNOWN_ERROR = 'unknownError',
}

export default UsersActionTypes;
