/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Center, Spinner, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toPng } from 'html-to-image';
import { useParams } from 'react-router';
import { point, polygon } from '@turf/turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
// import { useReactToPrint } from 'react-to-print';
import RadarGraph from 'components/RadarGraph/RadarGraph';
import ScatterGraph from 'components/ScatterGraph/ScatterGraph';
import PdfGenerator from 'components/PdfGenerator/PdfGenerator';
import User from 'core/model/user';
import { radarLoaderSelector, scatterLoaderSelector } from 'core/useCases/graphs/selectors';
import { getUser } from 'core/useCases/user/userActions';
import getUserFetcher from 'core/useCases/user/getUserByMail';
import userSelector from 'core/useCases/user/selectors';
import ResultText from 'components/ResultText/ResultText';
// import Header from 'components/Header/Header';
import Seo from 'components/Seo/Seo';
import Layout from 'components/Layout';
import Button from 'components/Button';

type PDF = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    container: any;
    isDirty: () => boolean;
    toString: () => string;
    toBlob: () => Promise<Blob>;
    toBuffer: () => Promise<NodeJS.ReadableStream>;
    on: (event: 'change', callback: () => void) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateContainer: (document: React.ReactElement<any>) => void;
    removeListener: (event: 'change', callback: () => void) => void;
};

type ResultsParams = {
    email: string;
};

const dangerPolygon = polygon([
    [
        [50.0, 0.0],
        [100.0, 0.0],
        [100.0, 0.0],
        [100.0, 50.0],
        [100.0, 50.0],
        [50.0, 0.0],
    ],
]);
const innovationPolygon = polygon([
    [
        [0.0, 5.0],
        [0.0, 100.0],
        [0.0, 100.0],
        [80.0, 100.0],
        [80.0, 100.0],
        [0.0, 5.0],
    ],
]);
const comfortPolygon = polygon([
    [
        [0.0, 0.0],
        [0.0, 5.0],
        [0.0, 5.0],
        [80.0, 100.0],
        [80.0, 100.0],
        [100.0, 100.0],
        [100.0, 100.0],
        [5.0, 0.0],
        [5.0, 0.0],
        [0.0, 0.0],
    ],
]);
const warningPolygon = polygon([
    [
        [5.0, 0.0],
        [100.0, 100.0],
        [100.0, 100.0],
        [100.0, 50.0],
        [100.0, 50.0],
        [50.0, 0.0],
        [50.0, 0.0],
        [5.0, 0.0],
    ],
]);

const computeZoneText = (x: number, y: number) => {
    const pointToCheck = point([x, y]);
    if (y > 100) {
        return [
            'graphs.scatter.texts.leader.title',
            'graphs.scatter.texts.leader.text',
            'graphs.scatter.texts.leader.type',
        ];
    }
    if (booleanPointInPolygon(pointToCheck, dangerPolygon)) {
        return [
            'graphs.scatter.texts.danger.title',
            'graphs.scatter.texts.danger.text',
            'graphs.scatter.texts.danger.type',
        ];
    }
    if (booleanPointInPolygon(pointToCheck, innovationPolygon)) {
        return [
            'graphs.scatter.texts.innovation.title',
            'graphs.scatter.texts.innovation.text',
            'graphs.scatter.texts.innovation.type',
        ];
    }
    if (booleanPointInPolygon(pointToCheck, comfortPolygon)) {
        return [
            'graphs.scatter.texts.comfort.title',
            'graphs.scatter.texts.comfort.text',
            'graphs.scatter.texts.comfort.type',
        ];
    }
    if (booleanPointInPolygon(pointToCheck, warningPolygon)) {
        return [
            'graphs.scatter.texts.warning.title',
            'graphs.scatter.texts.warning.text',
            'graphs.scatter.texts.warning.type',
        ];
    }

    return ['', ''];
};

const Results: FC = () => {
    const history = useHistory();
    const user: User = useSelector(userSelector) as User;
    const { t } = useTranslation();
    const [doc] = useState<PDF>(
        pdf(<PdfGenerator radarGraphHtml={undefined} scatterGraphHtml={undefined} zoneText={undefined} />),
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fileUrl, setFileUrl] = useState<string>();
    const [zoneText, setZoneText] = useState<string[]>();
    const radarRef = useRef<HTMLDivElement>(null);
    const scatterRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const loadScatterSelector = useSelector(scatterLoaderSelector);
    const loadRadarSelector = useSelector(radarLoaderSelector);
    const { email } = useParams<ResultsParams>();
    useEffect(() => {
        dispatch(
            getUser(getUserFetcher)({
                email,
            }),
        );
    }, [email]);

    useEffect(() => {
        if (!user) {
            history.push('/');
        }
    }, [user]);

    useEffect(() => {
        if (user?.pricingComplexityScore !== undefined && user?.pricingMaturityScore !== undefined) {
            setZoneText(computeZoneText(user?.pricingComplexityScore, user?.pricingMaturityScore));
        }
    }, [user]);

    useEffect(() => {
        const updater = async () => {
            if (scatterRef.current && radarRef.current) {
                const scatter = await toPng(scatterRef.current, {
                    cacheBust: true,
                });
                const radar = await toPng(radarRef.current, {
                    cacheBust: true,
                });

                if (radar && scatter && zoneText && doc) {
                    doc.updateContainer(
                        <PdfGenerator radarGraphHtml={radar} scatterGraphHtml={scatter} zoneText={zoneText} />,
                    );
                    doc.toBlob().then((blob) => setFileUrl(URL.createObjectURL(blob)));
                }
            }
        };
        if (loadScatterSelector && loadRadarSelector) {
            updater();
        }
    }, [loadScatterSelector, loadRadarSelector, zoneText, doc]);

    const componentRef = useRef<HTMLDivElement>(null);
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     pageStyle: `
    //         @page {
    //             /* définition des marges */
    //             margin: 1cm;
    //             /* page et orientation */
    //             size: A4 portrait;
    //         }
    //         @media print {
    //             no-print {
    //                 visibility: hidden;
    //             }
    //         }
    //     `,
    // });

    return (
        <Box ref={componentRef}>
            <Seo />
            <Layout image={{ src: '/person.jpg', alt: 'Layout 1' }}>
                <div className="results-container">
                    {true ? (
                        <>
                            {/* <Flex direction="column" justifyContent="center"> */}
                            {/* <Box> */}
                            <h3 className="heading-3">{t('result.title')}</h3>
                            <p className="text-md mt-5">{t('result.description')}</p>
                            <p className="text-md mt-5">
                                Discover our{' '}
                                <Text color="#0153f1" display="inline">
                                    <a
                                        href="https://pricemoov.com/resources/?type=success%20stories"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Customer Success Stories
                                    </a>
                                </Text>
                                , like{' '}
                                <Text color="#0153f1" display="inline">
                                    <a
                                        href="https://pricemoov.com/resources/success-stories/kiloutou/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Kiloutou
                                    </a>
                                </Text>{' '}
                                and{' '}
                                <Text color="#0153f1" display="inline">
                                    <a
                                        href="https://pricemoov.com/resources/success-stories/samkaup/netto"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Samkaup/Netto
                                    </a>
                                </Text>
                                , and see how pricing software has helped our customers grow their revenue and
                                transformed their pricing.
                            </p>
                            <Center>
                                <ScatterGraph
                                    pricingComplexityScore={user?.pricingComplexityScore}
                                    pricingMaturityScore={user?.pricingMaturityScore}
                                    scatterRef={scatterRef}
                                    t={t}
                                />
                            </Center>
                            <Center margin="20px auto" maxWidth="2xl">
                                {/* <Text align="center">{t(`${zoneText}`)}</Text> */}
                                {zoneText && <ResultText text={zoneText[1]} title={zoneText[0]} type={zoneText[2]} />}
                            </Center>
                            {/* </Box> */}
                            <Center>
                                <RadarGraph
                                    complexityRadarGraphData={user?.complexityRadarGraphData}
                                    maturityRadarGraphData={user?.maturityRadarGraphData}
                                    radarRef={radarRef}
                                    t={t}
                                />
                            </Center>
                            {/* </Flex> */}
                            {/* <Center margin="20px auto" maxW="768px">
                                <Text margin="40px 20px 20px">{t('result.conclusion')}</Text>
                            </Center> */}
                            <p className="text-md mt-5">Here are the results of your Pricing Maturity Assessment.</p>
                            <Center flexDirection="column" margin="20px auto" paddingY="10px">
                                {/* <Link href="https://pricemoov.com/fr/schedule-demo/" isExternal>
                                    <Button>{t('contact')}</Button>
                                </Link>
                                <Spacer /> */}
                                {/* {doc && fileUrl ? (
                                <a download="results.pdf" href={fileUrl}>
                                    <Button colorScheme="blue">{t('download')}</Button>
                                </a>
                            ) : null} */}
                                <Button onClick={window.print}>
                                    <span className="uppercase">{t('Access your results here')}</span>
                                </Button>
                            </Center>
                            <Center>
                                <p className="text-md mt-5">
                                    To benefit from a more detailed assessment of your pricing maturity and discuss all
                                    your pricing questions, book a meeting with one of our experts at{' '}
                                    <Text color="#0153f1">
                                        <a href="mailto:contact@pricemoov.com">contact@pricemoov.com</a>
                                    </Text>
                                    <p className="text-md mt-5">
                                        Let&apos;s price!
                                        <br />
                                        The Pricemoov Team
                                    </p>
                                </p>
                            </Center>
                        </>
                    ) : (
                        <Center>
                            <Spinner />
                        </Center>
                    )}
                </div>
            </Layout>
        </Box>
    );
};

export default Results;
