import User from 'core/model/user';
import UsersActionTypes from './types';
import { AddUserAction, GetUserAction } from './userActions';

export interface UserState {
    user: User | Record<string, unknown>;
}

const init = {
    user: null,
};

type Action = AddUserAction | GetUserAction;

const usersReducer = (state: UserState = init, action: Action): UserState => {
    switch (action.type) {
        case UsersActionTypes.ADD_USER:
            return { ...state, user: action.payload };
        case UsersActionTypes.GET_USER:
            return { ...state, user: action.payload };
        default:
            return state;
    }
};

export default usersReducer;
