import React, { FC } from 'react';
import { Document, Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

type PdfGeneratorProps = {
    scatterGraphHtml: string | undefined;
    radarGraphHtml: string | undefined;
    zoneText: string[] | undefined;
};
const PdfGenerator: FC<PdfGeneratorProps> = ({ scatterGraphHtml, radarGraphHtml, zoneText }) => {
    const { t } = useTranslation();
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4',
        },
        logo: {
            width: '111px',
        },
        section: {
            margin: 20,
            padding: 10,
            flexGrow: 1,
        },
    });

    if (scatterGraphHtml && radarGraphHtml && zoneText) {
        return (
            <Document>
                <Page size="A4">
                    <View style={{ margin: '30px' }}>
                        <View style={styles.logo}>
                            <Image src={`${process.env.PUBLIC_URL}/Pricemoov_Logo.png`} />
                        </View>
                        <View style={{ margin: '20px auto', textAlign: 'center' }}>
                            <Text style={{ fontWeight: 'bold' }}>{t('result.title')}</Text>
                            <Text style={{ fontSize: '12px', margin: '5px auto' }}>{t('result.description')}</Text>
                        </View>
                        <View>
                            <Image src={scatterGraphHtml} style={{ width: '50%', margin: '5px auto' }} />
                            <Text style={{ fontSize: '14px', fontWeight: 'extrabold', margin: '5px auto' }}>
                                {t(`${zoneText[0]}`)}
                            </Text>
                            <Text style={{ fontSize: '12px', margin: '5px auto' }}>{t(`${zoneText[1]}`)}</Text>
                        </View>
                        <View>
                            <Image src={radarGraphHtml} style={{ width: '50%', margin: '5px auto' }} />
                        </View>
                        <View style={styles.section}>
                            <Text style={{ fontSize: '12px' }}>{t('result.conclusion')}</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

    return (
        <Document>
            <Page size="A4">
                <View>
                    <Text>EMPTY</Text>
                </View>
            </Page>
        </Document>
    );
};
export default PdfGenerator;
