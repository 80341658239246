import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import version from './version';
import './styles/index.scss';
import './i18n';

const root = document.getElementById('root');

if (!root) {
    throw new Error('Missing root element');
}

const { sentryDsn, sentryEnvironment, ...props } = root.dataset;

Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnvironment,
    release: version ? `{sentry_project_frontend}@${version}` : undefined,
});

ReactDOM.render(
    <React.StrictMode>
        <App {...props} />
    </React.StrictMode>,
    root,
);
