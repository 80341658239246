import User from 'core/model/user';
import { BACKEND_URL } from 'utils/appUrl';
import { POST } from 'utils/http';
import { GetUserFetcher } from './userActions';

interface ResponseData {
    user: User;
}
const getUserFetcher: GetUserFetcher = async ({ email }): Promise<User> => {
    const userData = {
        email,
    };
    const response = (await POST<ResponseData>(`${BACKEND_URL}/users/by-mail`, userData)) as ResponseData;

    return response.user;
};

export default getUserFetcher;
