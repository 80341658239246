import { LoadRadarAction, LoadScatterAction } from './graphActions';
import GraphActionTypes from './types';

export interface GraphState {
    scatter: boolean;
    radar: boolean;
}

const init = {
    scatter: false,
    radar: false,
};

type Action = LoadScatterAction | LoadRadarAction;

const usersReducer = (state: GraphState = init, action: Action): GraphState => {
    switch (action.type) {
        case GraphActionTypes.LOAD_SCATTER:
            return { ...state, scatter: action.payload };
        case GraphActionTypes.LOAD_RADAR:
            return { ...state, radar: action.payload };
        default:
            return state;
    }
};

export default usersReducer;
