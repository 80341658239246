/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { CheckMark } from 'components/Icons';

type OptionsProps = {
    // icon
    title: string;
    disabled?: boolean;
    description?: string;
    selected: boolean;
    onClick: () => void;
    startIcon?: React.ReactNode;
};
const Options: React.FC<OptionsProps> = ({ startIcon, title, disabled, description, selected, onClick }) => {
    return (
        <Box className={`option-container  ${disabled ? 'disabled' : selected ? 'selected' : ''}`} onClick={onClick}>
            <div className="title-container">
                {startIcon && startIcon}
                <h6 className="text-md">{title}</h6>

                <i className={`tick-icon-container ${selected ? 'selected' : ''}`}>
                    <CheckMark height={24} width={24} />
                </i>
            </div>
            {description && <p className="description text-xs">{description}</p>}
        </Box>
    );
};

export default Options;
