/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, useEffect, useState } from 'react';
import axios from 'axios';
import { Box, FormControl } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import Question, { RadarGraphDataType } from 'core/model/question';
import i18n from 'i18n';
import { addUser } from 'core/useCases/user/userActions';
import addUserFetcher from 'core/useCases/user/addUser';
import questions from 'assets/questions.json';
import SectorActivity from 'components/Question/SectorActivity';
import Step from 'components/Step';
import Progress from 'components/Progress';
import InputComponent from 'components/FormElements/Input';
import Button from 'components/Button';
import Select from 'components/FormElements/Select';
import { phoneCountryCodes } from 'utils/countries';
import CheckboxField from 'components/FormElements/Checkbox';
import QuestionTile from '../Question/Question';
import generateSchema from './schema';
import submitForm from './submitHandler';
import RequiredLabel from './RequiredLabel';
// import Options from 'components/Blocks/Options/Options';
// import Step from 'components/Step';

const computeRadarGraphScore = (
    prev: number,
    score: number,
    question: Question,
    prop: keyof RadarGraphDataType,
    type: 'complexity' | 'maturity',
) => {
    switch (type) {
        case 'complexity':
            return question.perResourceWeight
                ? prev + score * ((question.perResourceWeight as RadarGraphDataType)[prop] as number)
                : prev;
        case 'maturity':
            return score / 10;
        default:
            return prev + score;
    }
};

type FormProps = {
    onQuesChange?: (d: number) => void;
};

export const RenderFormFields = (data: any, errors: any, type?: string) => {
    switch (data?.fieldType) {
        case 'text':
        case 'email':
        case 'phonenumber':
            return type === 'singleCTA' ? (
                <InputComponent
                    error={errors[data?.name]?.message || ''}
                    id={data?.name}
                    isRequired={data?.required}
                    placeholder="Email Address"
                    // label={`${data?.label}`}
                    type={data?.name.includes('email') ? 'email' : 'text'}
                    variant="outlined"
                />
            ) : (
                <InputComponent
                    error={errors[data?.name]?.message || ''}
                    id={data?.name}
                    isRequired={data?.required}
                    label={`${data?.label}`}
                    type={data?.name.includes('email') ? 'email' : 'text'}
                />
            );
        case 'select':
            return (
                <Select
                    defaultText={`Select ${data?.label}`}
                    error={errors[data?.name]?.message || ''}
                    height={50}
                    id={data?.name}
                    label={data?.label}
                    options={data?.options.map((option: { label: string }) => option?.label)}
                />
            );
        default:
            break;
    }
};

const FormComponent: FC<FormProps> = ({ onQuesChange }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('type');
    const [formData, setFormData] = useState(null);
    const [schema, setSchema] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [answers, setAnswers] = useState<{ step: number; score: number }[]>([]);
    const [toLeadGen, setToLeadGen] = useState<boolean>(false);
    const [initial, setInitial] = useState<boolean>(!paramValue);
    const [skip, setSkip] = useState<boolean>(false);
    const [sectorActivity, setSectorActivity] = useState<{
        sector: string;
        activity: string;
    }>({
        sector: paramValue ?? '',
        activity: '',
    });

    const [newsletter, setNewsletter] = useState<boolean>(false);
    const [agreement, setAgreement] = useState<boolean>(false);

    const [invalidEmail, setInvalidEmail] = useState<boolean>();
    const [invalidNumber, setInvalidNumber] = useState<boolean>();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const scrollToTop = () => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    };

    const form = useForm({
        resolver: yupResolver(schema),
    });

    const {
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
    } = form;

    const checkboxData = formData?.metaData?.find((_item: any) => _item.name === 'legalConsentOptions');
    const checkboxDataValue = JSON.parse(checkboxData?.value || '{}');

    const handleNextClick = (step: number, score: number | undefined) => {
        if (score === undefined) return;

        scrollToTop();

        // Checking if step is exists or not
        if (answers.find((item) => item.step === step)) {
            const newAnswers = answers.map((item) => {
                if (item.step === step) {
                    return { step, score };
                }

                return item;
            });

            setAnswers(newAnswers);
        } else {
            setAnswers([...answers, { step, score }]);
        }
        if (step === 2 && score === 1) {
            setCurrentStep(4);
            setSkip(true);
        } else if (currentStep === questions.length - 1) {
            setToLeadGen(true);
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevClick = () => {
        scrollToTop();

        if (currentStep === 0) {
            onQuesChange(0);
            setInitial(true);
        } else {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSectorClick = (sector: string, activity: string) => {
        scrollToTop();
        onQuesChange(1);
        setSectorActivity({ sector, activity });
        setInitial(false);
    };

    // const checkEmail = (value: string) => {
    //     if (value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
    //         setInvalidEmail(false);
    //         setEmail(value);
    //     } else {
    //         setInvalidEmail(true);
    //         setEmail('');
    //     }
    // };

    // const checkPhoneNumber = (value: string) => {
    //     if (typeof +value === 'number' && value.length > 1 && value.length <= 15) {
    //         setInvalidNumber(false);
    //         setPhoneNumber(value);
    //     } else {
    //         setInvalidNumber(true);
    //         setPhoneNumber('');
    //     }
    // };

    const handleFormSubmit = async (data) => {
        const { language } = i18n;
        const complexityAnswers = skip ? answers.slice(0, 6) : answers.slice(0, 8);
        const maturityAnswers = skip ? answers.slice(7, answers.length) : answers.slice(9, answers.length);
        // ---------------------------------------------------------------------------------------------
        const pricingComplexityScore = complexityAnswers.reduce((prev, curr) => {
            const question: Question = questions.find((q) => q.step === curr.step) as Question;
            if (question.baseScore) {
                return prev + (question.baseScore - curr.score) * question.globalWeight;
            }

            return prev + curr.score * question.globalWeight;
        }, 0);

        const pricingMaturityScore = maturityAnswers.reduce((prev, curr) => {
            const question: Question = questions.find((q) => q.step === curr.step) as Question;

            return prev + curr.score * question.globalWeight;
        }, 0);
        // ---------------------------------------------------------------------------------------------
        const complexityScores: RadarGraphDataType = complexityAnswers.reduce(
            (prev, curr) => {
                const question: Question = questions.find((q) => q.step === curr.step) as Question;

                return {
                    ...prev,
                    humanResource: computeRadarGraphScore(
                        prev.humanResource,
                        curr.score,
                        question,
                        'humanResource',
                        'complexity',
                    ),
                    data: computeRadarGraphScore(prev.data, curr.score, question, 'data', 'complexity'),
                    tools: computeRadarGraphScore(prev.tools, curr.score, question, 'humanResource', 'complexity'),
                    organization: computeRadarGraphScore(
                        prev.organization,
                        curr.score,
                        question,
                        'organization',
                        'complexity',
                    ),
                    processAndDashboards: computeRadarGraphScore(
                        prev.processAndDashboards,
                        curr.score,
                        question,
                        'processAndDashboards',
                        'complexity',
                    ),
                };
            },
            {
                humanResource: 0,
                data: 0,
                tools: 0,
                organization: 0,
                processAndDashboards: 0,
            },
        );

        const weights = questions.slice(0, 8).reduce(
            (prev, curr) => {
                return {
                    ...prev,
                    humanResource: prev.humanResource + (curr.perResourceWeight?.humanResource as number),
                    data: prev.data + (curr.perResourceWeight?.data as number),
                    tools: prev.tools + (curr.perResourceWeight?.tools as number),
                    organization: prev.organization + (curr.perResourceWeight?.organization as number),
                    processAndDashboards:
                        prev.processAndDashboards + (curr.perResourceWeight?.processAndDashboards as number),
                };
            },
            {
                humanResource: 0,
                data: 0,
                tools: 0,
                organization: 0,
                processAndDashboards: 0,
            },
        );

        const complexityRadarGraphData = {
            humanResource: (complexityScores.humanResource / (weights.humanResource * 10)) * 100,
            data: (complexityScores.data / (weights.data * 10)) * 100,
            tools: (complexityScores.tools / (weights.tools * 10)) * 100,
            organization: (complexityScores.organization / (weights.organization * 10)) * 100,
            processAndDashboards: (complexityScores.processAndDashboards / (weights.processAndDashboards * 10)) * 100,
        };

        const maturityRadarGraphData: RadarGraphDataType = {
            humanResource: maturityAnswers[0].score / 10,
            data: maturityAnswers[1].score / 10,
            tools: maturityAnswers[2].score / 10,
            organization: maturityAnswers[3].score / 10,
            processAndDashboards: maturityAnswers[4].score / 10,
        };

        const dataToSubmit = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            company: data.company,
            language,
            sector: sectorActivity.sector,
            activity: sectorActivity.activity,
            answers,
            pricingComplexityScore,
            pricingMaturityScore,
            complexityRadarGraphData,
            maturityRadarGraphData,
        };

        setIsLoading(true);
        const formToSubmit: any = [];
        Object.keys(data).forEach((item) => {
            if (typeof data[item] === 'object') {
                Object.keys(data[item]).map((_item: string) => {
                    formToSubmit?.push({
                        name: `${item}.${_item}`,
                        value: data?.[item]?.[_item] || false,
                    });
                });
            } else {
                formToSubmit?.push({ name: item, value: data[item] });
            }
        });

        const response = await dispatch(addUser(addUserFetcher)(dataToSubmit));
        await submitForm({
            id: formData?.guid,
            fields: formToSubmit,
            submittedAt: Date.now(),
            skipValidation: true,
        });
        // sendPdf(ReactPDF.renderToString(<PdfGenerator />), user.id as string)
        // setTimeout(() => {
        if (response) {
            history.push(`/results/${data.email}`);
        }
        reset();

        setIsLoading(false);
        // }, 1000);
    };

    useEffect(() => {
        (async function () {
            const data = JSON.stringify({
                formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
            });

            const res = await axios.post('https://rrgthv2443.execute-api.eu-west-1.amazonaws.com/stage/proxy', {
                formId: '2ccc545f-cbf3-4445-93b2-b352b1d279ce',
            });
            if (res.status === 200) {
                setFormData(res.data);
            }
        })();
    }, []);

    useEffect(() => {
        const schema = generateSchema(formData?.form?.formFieldGroups, formData?.form?.metaData);

        setSchema(schema);
    }, []);

    return sectorActivity.sector === '' || initial ? (
        // eslint-disable-next-line react/jsx-filename-extension
        <SectorActivity handleSectorClick={handleSectorClick} sectorActivity={sectorActivity} />
    ) : (
        <div>
            {toLeadGen ? (
                <Step title={"We're almost done"} showProgessBar>
                    <div className="form-container">
                        <div className="required-text-container">
                            <span className="text-md">All fields are required</span>
                        </div>
                        <FormProvider {...form}>
                            <form onSubmit={handleSubmit(handleFormSubmit)}>
                                {formData?.formFieldGroups?.map((item: any, i: number) => {
                                    return (
                                        <div key={i} className={`${item?.fields?.length > 1 ? 'splitted' : ''}`}>
                                            {item?.fields?.map((_item: any, i: number) => {
                                                return (
                                                    <Box key={i} marginBottom={2}>
                                                        {RenderFormFields(_item, errors)}
                                                    </Box>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                                <Box marginBottom={5} marginTop={5}>
                                    {checkboxDataValue?.communicationConsentCheckboxes?.map((_item: any, i: any) => {
                                        let myLabel = _item.label;

                                        if (_item.required) {
                                            myLabel = RequiredLabel(_item.label);
                                        }
                                        const id = _item?.communicationTypeId || '';

                                        return (
                                            <CheckboxField
                                                key={i}
                                                error={Boolean(errors?.LEGAL_CONSENT?.[`subscription_type_${id}`])}
                                                id={`LEGAL_CONSENT.communicationTypeId_${id}`}
                                                label={myLabel}
                                                required={_item.required}
                                            />
                                        );
                                    })}
                                    <Box marginTop={2}>
                                        {checkboxDataValue?.processingConsentCheckboxLabel &&
                                            checkboxDataValue?.processingConsentType === 'REQUIRED_CHECKBOX' && (
                                                <CheckboxField
                                                    error={Boolean(errors?.LEGAL_CONSENT?.processing)}
                                                    id="LEGAL_CONSENT.processing"
                                                    label={RequiredLabel(
                                                        checkboxDataValue?.processingConsentCheckboxLabel,
                                                    )}
                                                    required
                                                />
                                            )}
                                    </Box>
                                </Box>
                                <Button
                                    buttonType="submit"
                                    disabled={
                                        // !(
                                        //     firstName &&
                                        //     lastName &&
                                        //     company &&
                                        //     email &&
                                        //     agreement &&
                                        //     country &&
                                        //     phoneNumber &&
                                        //     jobTitle
                                        // ) ||
                                        invalidEmail || invalidNumber
                                    }
                                    type="primary"
                                >
                                    <span className="uppercase"> {formData?.submitText || t('see results')}</span>
                                </Button>
                            </form>
                        </FormProvider>
                    </div>
                </Step>
            ) : (
                <div>
                    <Progress
                        currentStep={currentStep}
                        total={questions.length}
                        value={(((currentStep + 1) * 100) / questions.length).toFixed(0)}
                    />
                    <Step
                        description={t(questions[currentStep].description)}
                        title={t(questions[currentStep].text)}
                        showProgessBar
                    >
                        <QuestionTile
                            answers={answers || []}
                            handleNextClick={handleNextClick}
                            handlePrevClick={handlePrevClick}
                            question={questions[currentStep]}
                        />
                    </Step>
                </div>
            )}
        </div>
    );
};

export default FormComponent;
