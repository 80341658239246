import { combineReducers, Reducer } from 'redux';
import { RootState } from 'core/store';
import sessionReducer from './session/reducer';
import SessionActionTypes from './session/types';
import usersReducer from './user/reducer';
import graphsReducer from './graphs/reducer';

export const appReducer = combineReducers({
    session: sessionReducer,
    users: usersReducer,
    graphs: graphsReducer,
});

const rootReducer: Reducer = (state: RootState | undefined, action): RootState => {
    // That trick allows us to clear store everytime user logout
    // Passing state as undefined will make all reducers fallback to their initial state value (aka reset)
    if (action.type === SessionActionTypes.CLEAR_SESSION_USER) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
