/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import * as yup from 'yup';

const generateSchema = (fields: any, checkboxFields: any) => {
    const schema: any = {};

    fields?.map((item: any) => {
        item?.fields?.map((_item: any) => {
            if (_item.required) {
                schema[_item.name] = yup.string().required(`Field is required`);
            } else {
                schema[_item.name] = yup.string();
            }
        });
    });

    // if (checkboxFields) {
    const checkboxData = checkboxFields?.find((_item: any) => _item?.name === 'legalConsentOptions');
    const checkboxDataValue = JSON.parse(checkboxData?.value || '{}');

    const consentValues: any = {};
    checkboxDataValue?.communicationConsentCheckboxes?.map((_item: any) => {
        const id = _item?.communicationTypeId;

        if (_item?.required) {
            consentValues[`subscription_type_${id}`] = yup.boolean().isTrue('Check the box');
        } else {
            consentValues[`subscription_type_${id}`] = yup.boolean();
        }
    });
    if (
        checkboxDataValue?.processingConsentCheckboxLabel &&
        checkboxDataValue?.processingConsentType === 'REQUIRED_CHECKBOX'
    ) {
        consentValues.processing = yup.boolean().isTrue('Check the box');
    }

    // if (Object.keys(consentValues).length > 0) {
    const LEGAL_CONSENT = yup.object().shape({
        // hello: yup.string().required(),
        ...consentValues,
    });
    schema.LEGAL_CONSENT = LEGAL_CONSENT;
    // }
    // }

    return yup.object().shape(schema);
};

export default generateSchema;
