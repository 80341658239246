import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

type GraphProps = {
    color: string;
    data: number[][] | number[];
};

const Graph: FC<GraphProps> = ({ color, data }) => {
    const x = data[0]?.[0] || 0;
    const y = data[0]?.[1] || 0;
    /* eslint-disable consistent-return */
    /* eslint-disable no-else-return */
    const calculateAxisExtremes = (value) => {
        if (value > 0) {
            if (value > 100 && value <= 200) {
                return [0, 200];
            }
            if (value > 200) {
                return [0, 300];
            } else {
                return [0, 100];
            }
        }
        if (value < 0) {
            if (value < -100) {
                return [-200, 0];
            } else {
                return [-100, 0];
            }
        }

        return [0, 100];
    };
    const [yMin, yMax] = calculateAxisExtremes(y);
    const [xMin, xMax] = calculateAxisExtremes(x);

    const options = {
        title: false,
        credits: {
            enabled: false,
        },
        chart: {
            spacing: [34, 34, 34, 34],
            plotBackgroundColor: '#F6F6F6',
            type: 'scatter',
        },
        plotOptions: {
            series: {
                linecap: 'square',
                lineWidth: 3,
                color,
                marker: { enabled: true },
            },
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            // categories: [0, 1, 2],
            // lineWidth: 2,
            min: xMin,
            max: xMax,
            tickInterval: 25,
            title: {
                text: 'Pricing Complexity',
            },
            labels: {
                format: '{value}%',
            },
            lineColor: '#FFFFFF',
            // min: 1,
            // max: 5,
        },
        yAxis: {
            min: yMin,
            max: yMax,
            tickInterval: y > 200 ? 50 : 25,
            lineColor: '#FFFFFF',
            labels: {
                format: '{value}%',
            },
            title: {
                text: 'Pricing Capacity',
            },
        },
        tooltip: {
            headerFormat: '<b>{series.name}</b><br/>',
            pointFormat: '{point.category}: {point.y}',
        },
        series: [
            {
                data,
            },
        ],
    };

    return (
        <div className="graph-container">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    );
};
export default Graph;
