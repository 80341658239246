import React, {
    FC,
    // useState
} from 'react';
import {
    // Box, Button,
    Flex,
    //  Menu, MenuButton, MenuItem, MenuList,
    Spacer,
    Image,
} from '@chakra-ui/react';
// import { ChevronDownIcon } from '@chakra-ui/icons';
// import FlagIcon from 'react-flagkit';
// import i18n from 'i18n';
// import translations from 'translations/_lang.json';

const Header: FC = () => {
    // const language = i18n.language.split('-');
    // const [currentLang, setCurrentLang] = useState<{ keyLang: string; label: string }>();
    // const handleChange = (lang: string) => {
    //     setCurrentLang(translations.languages.find((l) => l.keyLang === lang));
    //     i18n.changeLanguage(lang);
    // };
    // const selectedLang = ['US', 'FR'].includes(language[1]) ? language[1] : 'US';

    return (
        <Flex margin="0 auto">
            <div className="header-logo-container">
                <Image alt="Pricemoov" src={`${process.env.PUBLIC_URL}/pricemoov-logo.svg`} />
            </div>
            <Spacer />
            {/* <Box className="no-print" p="2">
                <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                        <FlagIcon
                            country={
                                currentLang?.keyLang === 'en'
                                    ? 'US'
                                    : currentLang?.keyLang.toUpperCase() || selectedLang
                            }
                        />
                    </MenuButton>
                    <MenuList>
                        {translations.languages.map((l) => (
                            <MenuItem
                                key={l.keyLang}
                                icon={<FlagIcon country={l.keyLang === 'en' ? 'US' : l.keyLang.toUpperCase()} />}
                                onClick={() => handleChange(l.keyLang)}
                            >
                                {l.label}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box> */}
        </Flex>
    );
};

export default Header;
