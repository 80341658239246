import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckMark } from 'components/Icons';

type CheckBoxProps = {
    id: any;
    label?: string | React.ReactNode;
    error?: boolean;
    required?: boolean;
    value?: any;
    onChange?: (value: any) => void;
};
const CheckboxField: React.FC<CheckBoxProps> = ({ id, label, error }) => {
    const { register } = useFormContext();

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <label className={`checkbox-component-container ${error ? 'error' : ''}`} htmlFor={`${id}`}>
            <input id={`${id}`} type="checkbox" {...register(id)} />
            <div className={`checkbox-box `}>
                <i className="icon-container">
                    <CheckMark />
                </i>
            </div>
            {label && typeof label === 'string' ? (
                <div className="text-md" dangerouslySetInnerHTML={{ __html: label }} />
            ) : (
                { label }
            )}
        </label>
    );
};

export default CheckboxField;
