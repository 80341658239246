import React from 'react';

type ProgressProps = {
    total: number;
    currentStep: number;
    value: any;
};
const Progress: React.FC<ProgressProps> = ({ currentStep, total, value }) => {
    const myArr = Array.from({ length: total });

    return (
        <div className="progress-bar-container">
            <div className="progress-items-container" style={{ gridTemplateColumns: `repeat(${total},1fr)` }}>
                {myArr.map((item, i) => {
                    return <div className={`item ${i <= currentStep ? 'selected-item' : ''}`} />;
                })}
            </div>
            <div className="progress-text">
                <h5 className="text-md">
                    <span>Progress:</span>
                    <span> {value}%</span>
                </h5>
            </div>
        </div>
    );
};

export default Progress;
