import React, { useState, useRef, useEffect } from 'react';
import { Carret } from 'components/Icons';
import Label from '../Label';

type DropdownProps = {
    id: string;
    options: string[];
    label: string;
    error?: string;
    height?: number;
    value?: string;
    defaultText: string;
    onChange?: (value: string) => void;
};
const Select: React.FC<DropdownProps> = ({ options, label, error, value, onChange, defaultText }) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const clickEventHandler = (ev: MouseEvent) => {
            if (dropdownRef.current) {
                const shouldClose = !dropdownRef.current.contains(ev.target as Node);
                if (shouldClose) {
                    setIsOpen(false);
                }
            }
        };
        setTimeout(() => {
            document.addEventListener('click', clickEventHandler);
        }, 10);

        return () => {
            document.removeEventListener('click', clickEventHandler);
        };
    }, []);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (option: string) => () => {
        onChange(option);
        setIsOpen(false);
    };

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div ref={dropdownRef} className="select-component-container">
            {label && (
                <button className="label-button" onClick={toggling} type="button">
                    <Label id="select">{label}</Label>
                </button>
            )}

            <button
                className={`select-display-container ${isOpen ? 'open' : ''} ${error ? 'error' : ''}`}
                onClick={toggling}
                type="button"
            >
                <span> {value || defaultText}</span>
                <span className="spacer" />
                <i
                    className="icon-container"
                    style={isOpen ? { transform: 'rotate(360deg)' } : { transform: 'rotate(180deg)' }}
                >
                    <Carret />
                </i>
            </button>

            <div className="select-list-container" style={isOpen ? { maxHeight: 284 } : { maxHeight: 0 }}>
                <ul className="dropdown-list">
                    {options.map((option) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                        <li key={option} className="option" onClick={onOptionClicked(option)}>
                            {option}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Select;
