import React from 'react';

type ButtonProps = {
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'primary' | 'secondary';
    buttonType?: 'button' | 'submit';
    [x: string]: any;
};
const Button: React.FC<ButtonProps> = ({
    children,
    disabled = false,
    onClick,
    type = 'primary',
    buttonType = 'button',
}) => {
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <button
            className={`button-component text-sm font-600 ${type}`}
            disabled={disabled}
            onClick={onClick}
            // eslint-disable-next-line react/button-has-type
            type={buttonType}
        >
            {children}
        </button>
    );
};

export default Button;
