import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import Label from '../Label';

type InputProps = {
    id: string;
    type?: 'text' | 'email';
    label?: string;
    placeholder?: string;
    error?: string | boolean;
    onChange?: (value: string) => void;
    isRequired?: boolean;
};
const Input: React.FC<InputProps> = ({ id, type = 'text', label, placeholder, error, isRequired }) => {
    const { register } = useFormContext();

    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div className="input-component-container">
            {label && (
                <Label id={id}>
                    {isRequired ? (
                        <>
                            {label}{' '}
                            <Box color="red" display="inline">
                                *
                            </Box>
                        </>
                    ) : (
                        label
                    )}
                </Label>
            )}
            <div>
                <input
                    {...register(id)}
                    className={`${error ? 'error' : ''}`}
                    // onChange={(e) => onChange?.(e.target.value)}
                    placeholder={placeholder}
                    required={isRequired}
                    type={type}
                />
            </div>
        </div>
    );
};

export default Input;
