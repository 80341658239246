import User from 'core/model/user';
import { ThunkAction } from '../../store';
import LoadingState from '../types';
import SessionActionTypes, { LoginErrorType } from './types';
import { setSessionLoading } from './setLoadingAction';

export interface SetSessionUserAction {
    type: SessionActionTypes.SET_SESSION_USER;
    payload: User;
}

export const setSessionUserAction = (user: User): SetSessionUserAction => ({
    type: SessionActionTypes.SET_SESSION_USER,
    payload: user,
});

export interface LogInErrorAction {
    type: SessionActionTypes.LOG_IN_ERROR;
    payload: LoginErrorType | null;
}

export const logInErrorAction = (message: LoginErrorType | null): LogInErrorAction => ({
    type: SessionActionTypes.LOG_IN_ERROR,
    payload: message,
});

export type LoginFetcher = (email: string, password: string) => Promise<User>;

const logUserIn = (loginFetcher: LoginFetcher) => (email: string, password: string): ThunkAction => async (
    dispatch,
) => {
    try {
        dispatch(setSessionLoading(LoadingState.LOADING));
        const user = await loginFetcher(email, password);
        dispatch(setSessionUserAction(user));
        dispatch(setSessionLoading(LoadingState.LOADED));
        dispatch(logInErrorAction(null));
    } catch (error) {
        dispatch(logInErrorAction(error.message));
        dispatch(setSessionLoading(LoadingState.LOADED));
    }
};

export default logUserIn;
