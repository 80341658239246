import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';
import { CardProps, SectionProps } from 'components/Blocks/RatioCardWrapper';

type RatioCardProps = SectionProps & {
    card: CardProps;
    selected?: boolean;
};

const RatioCard: FC<RatioCardProps> = ({ primary, secondary, card, weightage }) => {
    return (
        <Box className={`ratio-card ${card.selected ? 'selected' : ''}`} onClick={card.onClick}>
            <div className="primary-label text-md">
                {primary.text}
                <br />
                {card.primaryValue}%
            </div>
            <div className="ratio-value">
                <div
                    className={!primary.color ? 'primary' : primary.color}
                    style={{ maxWidth: `${weightage?.primary ?? 50}%` }}
                />
                <div
                    className={!secondary.color ? 'secondary' : secondary.color}
                    style={{ maxWidth: `${weightage?.secondary ?? 50}%` }}
                />
            </div>
            <div className="secondary-label text-md">
                {secondary.text}
                <br />
                {card.secondaryValue}%
            </div>
        </Box>
    );
};

export default RatioCard;
