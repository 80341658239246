export const BACKEND_URL =
    process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api' : 'http://localhost:8080/api';

export const SOCKET_URL =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080/'
        : process.env.REACT_APP_SOCKET_URL || 'http://localhost:8080/';

export const FRONTEND_URL =
    process.env.NODE_ENV === 'development'
        ? 'http://localhost:8081'
        : process.env.REACT_APP_FRONTEND_URL || 'http://localhost:8081';
