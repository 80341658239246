import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEO: FC = () => {
    const { t, i18n } = useTranslation();
    const meta = [
        {
            name: 'description',
            content: t('seo.description'),
        },
        {
            property: 'og:url',
            content: 'https://pricematurity.pricemoov.com',
        },
        {
            property: 'og:type',
            content: 'website',
        },
        {
            property: 'og:title',
            content: t('seo.title'),
        },
        {
            property: 'og:description',
            content: t('seo.description'),
        },
        {
            property: 'og:image',
            content: `${process.env.PUBLIC_URL}/Pricing_maturity.png`,
        },
        {
            property: 'twitter:card',
            content: 'summary',
        },
        {
            property: 'twitter:creator',
            content: 'Pricemoov',
        },
        {
            property: 'twitter:title',
            content: t('seo.title'),
        },
        {
            property: 'twitter:description',
            content: t('seo.description'),
        },
        {
            property: 'twitter:image',
            content: `${process.env.PUBLIC_URL}/Pricing_maturity.png`,
        },
    ];

    return <Helmet htmlAttributes={{ lang: i18n.language }} meta={meta} title={t('seo.title')} />;
};

export default SEO;
