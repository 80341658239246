import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import DataProvider from 'contexts/data';
import Router from 'pages/Router';
import AuthInitializer from 'contexts/auth';

const App: React.FC = () => {
    const theme = extendTheme({
        colors: {
            blue: {
                '50': '#E5EAFF',
                '100': '#B8C3FF',
                '200': '#8A9DFF',
                '300': '#5C77FF',
                '400': '#2E50FF',
                '500': '#002AFF',
                '600': '#0022CC',
                '700': '#001999',
                '800': '#001166',
                '900': '#000833',
            },
        },
        styles: {
            global: {
                'html, body': {
                    height: '100%',
                    width: '100%',
                    fontSize: '16px',
                    color: '#212529',
                },
                '#root': {
                    height: '100%',
                    width: '100%',
                },
            },
        },
    });

    return (
        <ChakraProvider theme={theme}>
            <DataProvider>
                <AuthInitializer>
                    <Router />
                </AuthInitializer>
            </DataProvider>
        </ChakraProvider>
    );
};

export default App;
