type submitFormProps = {
    id: string;
    fields: { name: string; value: string | boolean }[];
    submittedAt: string | number;
    skipValidation: boolean;
};

const submitForm = async (args: submitFormProps) => {
    const { id, submittedAt, fields, skipValidation } = args;
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_TRACKING_CODE}/${id}`;
    const data = {
        submittedAt,
        fields,
        skipValidation,
    };

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json; charset=utf8',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
        .then((response) => response.json())
        .then((res) => console.log(res));
};

export default submitForm;
