/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable one-var */
/* eslint-disable no-cond-assign */
const RequiredLabel = (label: string) => {
    // function which provide all occurances of provided value
    function getAllIndexes(arr: any, val: any) {
        let indexes = [],
            i = -1;
        while ((i = arr.indexOf(val, i + 1)) != -1) {
            indexes.push(i);
        }

        return indexes;
    }

    // getting all occurances of </
    const indexes = getAllIndexes(label, '</');

    const startingPart = label.slice(0, indexes[indexes.length - 1]);
    const endingPart = label.slice(indexes[indexes.length - 1], label.length);

    return `${startingPart} <span class="required" style="color:red;">*</span>${endingPart} `;
};

export default RequiredLabel;
