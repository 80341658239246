import GraphActionTypes from './types';

export interface LoadScatterAction {
    type: GraphActionTypes.LOAD_SCATTER;
    payload: boolean;
}

export interface LoadRadarAction {
    type: GraphActionTypes.LOAD_RADAR;
    payload: boolean;
}

export const loadScatterAction = (payload: boolean): LoadScatterAction => ({
    type: GraphActionTypes.LOAD_SCATTER,
    payload,
});

export const loadRadarAction = (payload: boolean): LoadRadarAction => ({
    type: GraphActionTypes.LOAD_RADAR,
    payload,
});
