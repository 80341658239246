import React, { FC } from 'react';
import RatioCard from 'components/Blocks/RatioCard';
import Options from 'components/Blocks/Options/Options';

export type CardProps = {
    primaryValue: number;
    secondaryValue: number;
    onClick: () => void;
    score: number;
    text?: string;
    selected: boolean;
    weightage?: {
        primary: number;
        secondary: number;
    };
};

export type SectionProps = {
    primary: {
        text: string;
        color?: 'primary' | 'secondary';
    };
    secondary: {
        text: string;
        color?: 'primary' | 'secondary';
    };
    weightage?: {
        primary: number;
        secondary: number;
    };
};

export type RatioSectionProps = SectionProps & {
    ratioCards: CardProps[];
};

const RadioCardWrapper: FC<RatioSectionProps> = ({ primary, secondary, ratioCards }) => {
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <div className="ratio-section">
            <div className="ratio-tags">
                <div className="tag">
                    <div
                        className={
                            !primary.color || primary.color === 'primary' ? 'primary-circle' : 'secondary-circle'
                        }
                    />
                    <div className="text-lg">{primary.text}</div>
                </div>
                <div className="tag">
                    <div
                        className={
                            !secondary.color || secondary.color === 'secondary' ? 'secondary-circle' : 'primary-circle'
                        }
                    />
                    <div className="text-lg">{secondary.text}</div>
                </div>
            </div>
            <div className="ratio-cards-conntainer">
                {ratioCards.map((card) =>
                    card.score === 0 ? (
                        <div className="full-width-item">
                            <Options onClick={card.onClick} selected={card.selected} title={card.text || ''} />
                        </div>
                    ) : (
                        <RatioCard card={card} primary={primary} secondary={secondary} weightage={card.weightage} />
                    ),
                )}
            </div>
        </div>
    );
};

export default RadioCardWrapper;
