import User from 'core/model/user';
import LoadingState from '../types';
import { SetUserDataAction } from './validateCredentials';
import { SetSessionUserAction, LogInErrorAction } from './logInAction';
import { ClearSessionUserAction } from './logOutAction';
import SessionActionTypes, { LoginErrorType, SignupErrorType, UpdateUserPasswordErrorType, UserData } from './types';
import { SetSessionLoadingAction } from './setLoadingAction';
import { SelectMenuItem } from './selectMenuItem';

const getCurrentMenuItem = (): number => {
    if (window.location.pathname.includes('jcas')) {
        return 1;
    }
    if (window.location.pathname.includes('patients')) {
        return 2;
    }
    if (window.location.pathname.includes('tasks')) {
        return 3;
    }
    if (window.location.pathname.includes('users')) {
        return 4;
    }

    return 2;
};

export interface SessionState {
    user: User | null;
    userData: UserData | null;
    loginErrorType: LoginErrorType | null;
    signupErrorType: SignupErrorType | null;
    updateUserPasswordErrorType: UpdateUserPasswordErrorType | null;
    loading: LoadingState;
    menuItem: number;
    signupUserSuccess: boolean | null;
}

const init = {
    user: null,
    userData: null,
    loginErrorType: null,
    signupErrorType: null,
    updateUserPasswordErrorType: null,
    loading: LoadingState.NOT_LOADED,
    menuItem: getCurrentMenuItem(),
    signupUserSuccess: null,
};

type Action =
    | SetSessionUserAction
    | LogInErrorAction
    | ClearSessionUserAction
    | SetUserDataAction
    | SetSessionLoadingAction
    | SelectMenuItem;

const sessionReducer = (state: SessionState = init, action: Action): SessionState => {
    switch (action.type) {
        case SessionActionTypes.SET_SESSION_USER:
            return { ...state, user: action.payload };
        case SessionActionTypes.CLEAR_SESSION_USER:
            return { ...state, user: null };
        case SessionActionTypes.LOG_IN_ERROR:
            return { ...state, loginErrorType: action.payload };
        case SessionActionTypes.SET_USER_DATA:
            return { ...state, userData: action.payload };
        case SessionActionTypes.SET_SESSION_LOADING:
            return { ...state, loading: action.payload };
        case SessionActionTypes.SELECT_MENU_ITEM:
            return { ...state, menuItem: action.payload };
        default:
            return state;
    }
};

export default sessionReducer;
