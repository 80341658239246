import React from 'react';

type LabelProps = {
    id: string;
};
const Label: React.FC<LabelProps> = ({ children, id }) => {
    return (
        <label className="label-component text-xs" htmlFor={id}>
            {children}
        </label>
    );
};

export default Label;
