import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from 'components/Alert';

interface ResultTextProps {
    title: string;
    text: string;
    type: any;
}

const ResultText: FC<ResultTextProps> = ({ title, text, type }) => {
    const { t } = useTranslation();

    const resultType = t(`${type}`);

    const alertType = (resultType === 'danger' && 'danger') || (resultType === 'warning' && 'warning') || 'success';

    return (
        <div>
            <Alert description={t(`${text}`)} title={t(`${title}`)} type={alertType} />
        </div>
    );
};

export default ResultText;
