import React from 'react';

export type IconProps = {
    height?: number;
    width?: number;
    color?: string;
};

export const CheckMark: React.FC<IconProps> = ({ height = 11.25, width = 13.5, color }) => {
    return (
        <svg fill={color} height={height} viewBox="0 0 14 12" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.70745 11.25L0.25 5.92105L1.68617 4.73684L5.13298 7.10526L13.1755 0L13.75 0.592105L5.70745 11.25Z"
                fill="#0153F1"
            />
        </svg>
    );
};

export const RetailIcom: React.FC<IconProps> = ({ width = 36, height = 36 }) => {
    return (
        <svg fill="none" height={height} viewBox="0 0 36 36" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.696 30.065a1.808 1.808 0 10-3.52-.83 1.808 1.808 0 003.52.83z"
                fill="url(#paint0_linear_3324_1862)"
            />
            <path
                d="M23.045 30.944a1.808 1.808 0 10-2.557-2.557 1.808 1.808 0 002.557 2.557z"
                fill="url(#paint1_linear_3324_1862)"
            />
            <path
                d="M31.288 5.764v.902c0 .532-.483 1.078-1.017 1.078l-1.876-.053-2.24 9.64-1.666 8.216a1.42 1.42 0 01-1.392 1.136H10.85a1.42 1.42 0 01-1.392-1.136L7.4 15.409a1.42 1.42 0 011.392-1.702h1.908a6.053 6.053 0 0012.102 0h1.09l1.487-7.44a1.825 1.825 0 011.79-1.47h3.16a.963.963 0 01.96.967z"
                fill="url(#paint2_linear_3324_1862)"
            />
            <path
                d="M21.025 17.836a6.053 6.053 0 10-8.56-8.56 6.053 6.053 0 008.56 8.56z"
                fill="url(#paint3_linear_3324_1862)"
            />
            <path
                d="M16.746 19.639a6.083 6.083 0 116.082-6.082 6.088 6.088 0 01-6.082 6.082zm0-12.106a6.023 6.023 0 106.024 6.024 6.029 6.029 0 00-6.024-6.024z"
                fill="url(#paint4_linear_3324_1862)"
            />
            <path
                d="M16.522 16.016a.44.44 0 01-.33-.147l-2.47-2.772a.442.442 0 01.658-.588l2.104 2.359 2.346-3.282a.443.443 0 01.718.514l-2.666 3.731a.446.446 0 01-.337.184l-.023.001z"
                fill="#fff"
            />
            <defs>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint0_linear_3324_1862"
                    x1="31.569"
                    x2="-5.526"
                    y1="23.545"
                    y2="35.137"
                >
                    <stop stopColor="#18B7F1" />
                    <stop offset="1" stopColor="#185EF1" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint1_linear_3324_1862"
                    x1="32.478"
                    x2="-4.617"
                    y1="26.318"
                    y2="37.91"
                >
                    <stop stopColor="#18B7F1" />
                    <stop offset="1" stopColor="#185EF1" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint2_linear_3324_1862"
                    x1="7.367"
                    x2="31.288"
                    y1="15.742"
                    y2="15.742"
                >
                    <stop stopColor="#18B7F1" />
                    <stop offset="1" stopColor="#185EF1" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint3_linear_3324_1862"
                    x1="12.75"
                    x2="20.25"
                    y1="9.75"
                    y2="20.25"
                >
                    7<stop stopColor="#D3FFFF" />
                    <stop offset="1" stopColor="#46C7C7" />
                </linearGradient>
                <linearGradient
                    gradientUnits="userSpaceOnUse"
                    id="paint4_linear_3324_1862"
                    x1="10.664"
                    x2="22.828"
                    y1="13.557"
                    y2="13.557"
                >
                    <stop stopColor="#C5FFFF" />
                    <stop offset="1" stopColor="#B3D6D6" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export const Carret: React.FC<IconProps> = ({ height = 6, width = 10.5, color = '#1D2328' }) => {
    return (
        <svg fill={color} height={height} viewBox="0 0 11 6" width={width} xmlns="http://www.w3.org/2000/svg">
            <path
                clipRule="evenodd"
                d="M10.7899 4.77538L6.22462 0.210111C5.94447 -0.0700362 5.49026 -0.0700362 5.21011 0.210111L0.644837 4.77538C0.36469 5.05553 0.36469 5.50974 0.644837 5.78989C0.924985 6.07004 1.3792 6.07004 1.65934 5.78989L5.71736 1.73187L9.77538 5.78989C10.0555 6.07004 10.5097 6.07004 10.7899 5.78989C11.07 5.50974 11.07 5.05553 10.7899 4.77538Z"
                fill="#1D2328"
                fillRule="evenodd"
            />
        </svg>
    );
};
