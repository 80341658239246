import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import langFr from './translations/fr.json';
import langEn from './translations/en.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: 'fr',
        resources: {
            // fr: { translation: langFr },
            en: { translation: langEn },
        },
        detection: {
            order: ['navigator'],
        },
        interpolation: {
            escapeValue: false,
        },
        fallbackLng: 'en',
    });
i18n.changeLanguage();

export default i18n;
