import React, { FC } from 'react';
// import RatioSection, { RatioSectionProps } from 'components/RatioSection';

const Component: FC = () => {
    // const data: RatioSectionProps = {
    //     primary: {
    //         text: 'B2C',
    //         color: 'primary',
    //     },
    //     secondary: {
    //         text: 'B2B',
    //         color: 'secondary',
    //     },
    //     ratioCards: [
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 50,
    //             secondaryValue: 50,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //         {
    //             primaryValue: 80,
    //             secondaryValue: 20,
    //             onClick: () => {
    //                 console.log('first');
    //             },
    //         },
    //     ],
    // };

    return <h1>hello world</h1>;
};

export default Component;
