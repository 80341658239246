import User from 'core/model/user';
// import { BACKEND_URL } from 'utils/appUrl';
// import { POST } from 'utils/http';
import { AddUserFetcher } from './userActions';

// interface ResponseData {
//     user: User;
// }
const addUserFetcher: AddUserFetcher = async ({
    firstName,
    lastName,
    email,
    language,
    company,
    sector,
    activity,
    pricingComplexityScore,
    pricingMaturityScore,
    answers,
    complexityRadarGraphData,
    maturityRadarGraphData,
}): Promise<User> => {
    const userData = {
        firstName,
        lastName,
        email,
        language,
        company,
        sector,
        activity,
        pricingComplexityScore,
        pricingMaturityScore,
        answers,
        complexityRadarGraphData,
        maturityRadarGraphData,
    };
    // const response = (await POST<ResponseData>(`${BACKEND_URL}/users/new`, userData)) as ResponseData;

    // console.log('__response', response);

    return { id: '1', ...userData };
    // return response.user;
};

export default addUserFetcher;
