import React from 'react';

type AlertProps = {
    type: 'danger' | 'success' | 'warning';
    title: string;
    description: string | React.ReactNode;
};
const Alert: React.FC<AlertProps> = ({ title, description, type = 'success' }) => {
    return (
        <div className={`alert-container ${type}`}>
            <h6 className="heading-6">{title}</h6>
            <p>{description}</p>
        </div>
    );
};

export default Alert;
