/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import sectorActivities from 'assets/sectorActivities.json';
import Options from 'components/Blocks/Options/Options';
import OptionsWrapper from 'components/Blocks/OptionsWrapper/OptionsWrapper';
import Step from 'components/Step';
import { RetailIcom } from 'components/Icons';

interface SectorActivityProps {
    handleSectorClick: (sector: string, activity: string) => void;
    sectorActivity: { sector: string; activity: string };
}

const SectorActivity: FC<SectorActivityProps> = ({ handleSectorClick, sectorActivity }: SectorActivityProps) => {
    const { t } = useTranslation();

    const defaultActivities: any = sectorActivities.find((sector) => t(sector.label) === sectorActivity.sector) || [];

    const [selectedSector, setSelectedSector] = useState(sectorActivity.sector || '');
    const [selectedActivity, setSelectedActivity] = useState(sectorActivity.activity || '');
    // const [tabToShow, setTabToShow] = useState<'sector' | 'activity'>(sectorActivity.sector ? 'activity' : 'sector');

    const [activities, setActivties] = useState<string[]>(defaultActivities?.activity);

    const handleSectorChange = (label: string) => {
        setSelectedSector(t(label));
        const activity = sectorActivities.find((sector) => sector.label === label)?.activity;
        if (activity) {
            setActivties(activity);
        }
        setSelectedActivity('');
    };

    // const handleActivityChange = (selected: string) => {
    //     setSelectedActivity(t(selected));
    // };

    const handleSector = () => {
        // if (typeof window !== 'undefined') {
        //     window.scrollTo(0, 0);
        // }
        // setTabToShow('activity');
        handleSectorClick(selectedSector, '');
    };

    // const handleActivity = () => {
    //     handleSectorClick(selectedSector, selectedActivity);
    // };

    // const handleBack = () => {
    //     setTabToShow('sector');
    // };

    // useEffect(() => {
    //     handleSectorChange('sel');
    // }, []);

    return (
        <Step
            mainDescription="Your customized report in under 5 minutes.
            Assess your company’s level of pricing maturity and benchmark your position in the industry."
            mainTitle="Pricing Maturity Assessment"
            primaryBtn={{
                title: 'NEXT',
                disabled: selectedSector === '',
                onClick: handleSector,
            }}
            title="What is your sector of activity?"
        >
            <OptionsWrapper>
                {sectorActivities.map((sector) => {
                    return (
                        <Options
                            onClick={() => handleSectorChange(sector.label)}
                            selected={t(sector.label) === selectedSector}
                            startIcon={<RetailIcom />}
                            title={t(sector.label)}
                        />
                    );
                })}
            </OptionsWrapper>
        </Step>
    );
    // return tabToShow === 'sector' ? (
    //     <Step
    //         mainDescription="In less than 15 multiple-choice questions, evaluate your pricing maturity level along with
    //            custom-tailored resources and next steps."
    //         mainTitle="Pricing Maturity Assessment"
    //         primaryBtn={{
    //             title: 'NEXT',
    //             disabled: selectedSector === '',
    //             onClick: handleSector,
    //         }}
    //         title="What is your sector of activity?"
    //     >
    //         <OptionsWrapper>
    //             {sectorActivities.map((sector) => {
    //                 console.log('___sector.label', sector.label);

    //                 return (
    //                     <Options
    //                         onClick={() => handleSectorChange(sector.label)}
    //                         selected={t(sector.label) === selectedSector}
    //                         startIcon={<RetailIcom />}
    //                         title={t(sector.label)}
    //                     />
    //                 );
    //             })}
    //         </OptionsWrapper>
    //     </Step>
    // ) : (
    //     <Step
    //         primaryBtn={{
    //             title: 'NEXT',
    //             disabled: selectedActivity === '',
    //             onClick: handleActivity,
    //         }}
    //         secondaryBtn={{ title: 'BACK', onClick: handleBack }}
    //         title="What is your activity?"
    //     >
    //         {activities.length > 0 && (
    //             <OptionsWrapper>
    //                 {activities.map((activity) => (
    //                     <Options
    //                         onClick={() => handleActivityChange(activity)}
    //                         selected={t(activity) === selectedActivity}
    //                         title={t(activity)}
    //                     />
    //                 ))}
    //             </OptionsWrapper>
    //         )}
    //     </Step>
    // );
};

export default SectorActivity;
